import * as React from "react";
import ArticleTemplate from "../../templates/article";
import { SEO } from "../../components/seo";
import { graphql } from "gatsby";

const ArticlePage = ({ data }: { data: any }) => {
  const articleData = data.airtable.data;
  const articleContent: ArticleContent = {
    title: articleData.Name,
    tags: articleData.Tags,
    date: articleData.Date,
    mdx: articleData.Notes.childMdx.body,
    slug: articleData.Slug,
    category: articleData.Category,
  };

  return <ArticleTemplate content={articleContent} />;
};

export const query = graphql`
  query ($id: String) {
    airtable(id: { eq: $id }) {
      data {
        Name
        Tags
        Date
        Slug
        Category
        Summary
        Notes {
          childMdx {
            body
          }
        }
      }
    }
  }
`;
export default ArticlePage;

export const Head = ({ data }: { data: any }) => {
  const articleData: AirtableRecord = data.airtable.data;
  return (
    <SEO
      title={articleData.Name}
      description={articleData.Summary}
      pathname={`/experiences/${articleData.Slug}`}
    />
  );
};
